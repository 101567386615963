.edit-fortnox-token-btn {
	margin-top: 20px;
	padding-right: 15px;
	margin-bottom: 10px;
	display: inline-block;
}

.remove-fortnox-token-btn {
	margin-top: 20px;
	margin-bottom: 10px;
	display: inline-block;
}

.ticket-div {
	font-family: Arial;
	font-weight: bold;
	position: relative;
	/*background: rgb(61 178 39);*/
	background: #f6b758;
	display: inline-block;
	margin: 10px 5px 5px 0;
	cursor: pointer;
}

.ticket-corner {
	content: '';
	position: absolute;
	z-index: 100;
}

.ticket-top-right {
	top: 0;
	right: 0;
	border-bottom: #fff 15px solid;
	border-left: #fff 15px solid;
	border-radius: 0 0 0 20px;
}

.ticket-top-left {
	top: 0;
	left: 0;
	border-bottom: #fff 15px solid;
	border-right: #fff 15px solid;
	border-radius: 0 0 20px 0;
}

.ticket-bottom-right {
	bottom: 0;
	right: 0;
	border-left: #fff 15px solid;
	border-top: #fff 15px solid;
	border-radius: 20px 0 0 0;
}

.ticket-bottom-left {
	bottom: 0;
	left: 0;
	border-right: #fff 15px solid;
	border-top: #fff 15px solid;
	border-radius: 0 20px 0 0;
}

.ticket-sides {
	border-style: dotted;
	border-color: white;
	border-width: 4px;
	border-top: none;
	border-bottom: none;
	margin: -2px;
}

.ticket-content {
	margin: 12px;
	border: 2px solid black;
	border-radius: 15px;
	padding: 10px;
	text-align: center;
}

ul.scopes {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

ul.scopes > li {
	display: list-item;
	width: 50%;
}
