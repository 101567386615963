/* table.extract-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}
table.extract-table tr:nth-of-type(even) {
  background: #f5f5f5;
}
table.extract-table th {
  font-weight: bold;
}
table.extract-table td,
table.extract-table th {
  padding: 6px;
  text-align: left;
}
@media only screen and (max-width: 1000px) {
  table.extract-table {
    border: 0;
  }
  
  table.extract-table table,
  table.extract-table thead,
  table.extract-table tbody,
  table.extract-table th,
  table.extract-table td,
  table.extract-table tr {
    display: block;
  }

  table.extract-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  table.extract-table tr {
    border: 0;
    margin-top: 2em;
  }

  table.extract-table td {
    border-bottom: 1px solid #eee;
    position: relative;
    border: 1px solid #ccc;
  }
  table.extract-table td.button-group {
    text-align: left;
  }
}

.button-group > * {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

table.extract-table td.button-group {
  text-align: right;
} */

.table-card {
	padding-top: 2rem;
}

table {
	width: 100%;
	overflow: hidden;
	border-collapse: collapse;
	border-style: hidden;
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem 0.5rem 0 0;
}
td {
	border: 1px solid #ccc;
}
tr:nth-of-type(odd) {
	background: #eee;
}
th {
	border-collapse: collapse;
	background: #2d7e1f;
	color: white;
	font-weight: bold;
}
td,
th {
	word-wrap: break-word;
	max-width: 300px;
	padding: 6px;
	text-align: left;
}

@media only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px) {
	table,
	thead,
	tbody,
	th,
	td,
	tr {
		max-width: none;
		display: block;
	}

	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr {
	}

	td {
		border: none;
		position: relative;
		padding-left: 50%;
	}

	td:before {
		position: absolute;
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}

	td:nth-of-type(1):before {
		content: 'Jobbnamn';
	}
	td:nth-of-type(2):before {
		content: 'Resurs';
	}
	td:nth-of-type(3):before {
		content: 'Dokument';
	}
	td:nth-of-type(4):before {
		content: 'Senast körd';
	}
}

.extract-action-btns {
	padding-top: 0;
	min-width: 174px;
	width: 174px;
}

.extract-action-btns > .btn {
	margin-top: 6px;
	padding: 5px;
}
.extract-action-btns > .btn:not(:last-child) {
	margin-right: 5px;
}

.edit-btn {
	width: 81px;
}

.run-btn,
.history-btn,
.stop-btn {
	width: 70px;
}

.delete-btn {
	width: 30px;
}

@media (min-width: 992px) {
	.extract-action-btns {
		min-width: 214;
		width: 214px;
	}
}
.anchor-link-color {
	color: #9baf4a ;
	
	background-color: transparent;
}

.anchor-link-color:hover {
	color: #64751e ;
	
	background-color: transparent;
}

.form-control:focus {
	color: #212529 !important;
	background-color: #fff !important;
	border-color: #d0dca0 !important;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(160, 184, 65, 0.25) !important;
  }