@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600;700&display=swap');

.App {
	text-align: center;
}

body {
	font-family: 'Montserrat', sans-serif !important;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #e0f39b !important;
	border-color: #a8be50 !important;
}

#page-content-wrapper {
	width: 100%;
}

#page-content-wrapper h3 {
	margin-bottom: 1rem;
}

.navbar-collapse {
	justify-content: flex-end;
}

li {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.container {
	margin-top: 20px;
	width: 110%;
	margin-left: auto;
	margin-right: auto;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.dashboard-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.dashboard-images {
	align-items: center;
	justify-content: center;
	display: flex;
}

.dashboard-logo {
	height: 66px;
	object-fit: contain;
	margin: 20px 15px;
}

.dashboard-text {
	font-size: 12px;
	margin-top: 15px;
}

.dashboard-instruction-text {
	font-size: 14px;
	margin-top: 15px;
}

.about-logo {
	height: 68px;
	object-fit: contain;
	margin: 15px 0;
}

.job-run-table-item {
	margin-bottom: 30px;
}

.spinner-status-container {
	display: flex;
	justify-content: center;
}

.latest-job-status {
	font-weight: bold;
	display: flex;
	justify-content: center;
}

.spinner-item {
	display: flex !important;
	justify-content: center !important;
}

.extracting-data-message {
	font-weight: bold;
	margin-top: 30px;
}

.share-w-team-btn {
	padding-left: 500px;
}

.edit-team-btn {
	right: 90px;
	bottom: 15px;
	position: absolute;
}

.remove-team-btn {
	right: 25px;
	bottom: 15px;
	position: absolute;
}

.team-table-item {
	margin-bottom: 30px;
	position: relative;
}

.team-members-name {
	left: 7px;
	top: 6px;
	position: relative;
}

.tbody-team-item {
	position: relative;
	margin: 30px;
}

.team-members-tasks {
	left: 7px;
	top: 6px;
	position: relative;
	margin-bottom: 20px;
}

.team-members-owner {
	left: 7px;
	top: 6px;
	position: relative;
	margin-bottom: 10px;
}

.add-task-to-team {
	margin-right: 5px;
}

.add-task-label {
	margin-right: 25px;
}

.add-task-container {
	border: 1px solid #d6d6d6;
	border-radius: 0.25rem;
	display: flex;
	flex-wrap: wrap;
	padding: 10px 10px 5px 10px;
	margin-bottom: 10px;
}

.gmail-label {
	margin-top: 10px;
}

.input-tag {
	background: white;
	border: 1px solid #d6d6d6;
	border-radius: 0.25rem;
	display: flex;
	flex-wrap: wrap;
	padding: 10px 10px 5px 10px;
	margin-bottom: 10px;
}

.input-tag input {
	border: 1px solid #d6d6d6;
	border-radius: 10px;
	width: 100%;
	outline: none;
}

.input-tag__tags {
	display: inline-flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	width: 100%;
}

.input-tag__tags li {
	align-items: center;
	background: #007bff;
	border-radius: 10px;
	color: white;
	display: flex;
	font-weight: 300;
	list-style: none;
	margin-bottom: 5px;
	margin-right: 5px;
	padding: 5px 10px;
}

.input-tag__tags li button {
	align-items: center;
	appearance: none;
	background: white;
	border: none;
	border-radius: 50%;
	color: #007bff;
	cursor: pointer;
	display: inline-flex;
	font-size: 12px;
	height: 15px;
	justify-content: center;
	line-height: 0;
	margin-left: 8px;
	padding: 0;
	transform: rotate(45deg);
	width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
	background: none;
	flex-grow: 1;
	padding: 0;
}
.choose-language-text {
	margin-top: 20px;
	margin-bottom: 20px;
}

.select-all-btn {
	margin-top: 20px;
	margin-bottom: 20px;
}

.deselect-all-btn {
	margin-top: 20px;
	margin-bottom: 20px;
}

.add-fortnox-token-btn {
	margin-top: 20px;
}

.edit-fortnox-token-btn {
	margin-top: 20px;
	padding-right: 15px;
	margin-bottom: 10px;
	display: inline-block;
}

.inv-toast-txt {
	display: contents;
}

.inv-row-container {
	padding: 10px;
	margin-left: 3px;
}

.btn-txt {
	font-size: 12px;
}

.decline {
	margin-left: 10px;
}
.accept {
	margin-left: 5px;
}

.extract-team-name-title {
	margin-top: 30px;
	float: left;
}

.select-resource-drop-down {
	width: 80%;
	float: left;
}

.remove-fortnox-token-btn {
	margin-top: 20px;
	margin-bottom: 10px;
	display: inline-block;
}

.select-resource-drop-down {
	width: 80%;
	float: left;
}

.error-modal .modal-content {
	border-width: 3px;
}

.error-modal.error .modal-content {
	border-color: #c82333;
}

.error-modal .modal-header {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.error-modal.error .modal-header {
	background-color: #c82333;
	color: white;
}

.error-modal.warning .modal-content {
	border-color: darkorange;
}

.error-modal.warning .modal-header {
	background-color: orange;
	color: white;
}

.error-modal.success .modal-content {
	border-color: darkgreen;
}

.error-modal.success .modal-header {
	background-color: rgb(61 178 39);
	color: white;
}

@media screen and (min-width: 992px) {
	/* Hack for stopping onclick navbar animation on desktop */
	#navbarNav.collapsing {
		transition: none;
	}
}

.clickable {
	cursor: pointer;
	text-decoration: underline;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.clickable:hover {
	transform: translateY(-1px);
}
.modal-section > h5 {
	font-weight: 600;
}
.modal-section > p {
	font-size: 12px;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/*Pagination styles for components: extracttasklist, pagination*/
a {
	cursor: pointer;
}

.form-select:focus {
	border-color: #d0dca0 !important;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(160, 184, 65, 0.25);
}

.new-user-info {
	background-color: #e4efbb;
}
